<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">{{showLang('lux.edit.lux.info')}}</div>
    <div class="lux-edit-area">
      <Form ref="form" :model="form" :rules="formRules" :label-width="110" class="lux-form-area">
        <FormItem prop="name" :label="showLang('com.tab.title')">
          <div class="flex-row">
            <Input ref="name" type="text" v-model="form.name" :placeholder="showLang('save.name')"></Input>
            <i-switch v-model="form.isOuter" size="large" true-color="green" false-color="gray" style="margin-left:10px;width:65px">
              <span slot="open">外部</span>
              <span slot="close">内部</span>
            </i-switch>
          </div>
        </FormItem>
        <FormItem prop="ruleId" :label="showLang('lux.Apply.Rules')">
          <Select v-model="form.ruleId" :placeholder="showLang('save.select')">
            <template v-for="(item, idx) in formArgs.rules">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
        </FormItem>
        <FormItem prop="transTypes" label="通信设备类型">
          <Select v-model="form.transType" :placeholder="showLang('save.select')">
            <template v-for="(item, idx) in formArgs.transTypes">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
        </FormItem>
        <FormItem prop="code" label="通信设备ID">
          <Input ref="code" type="text" v-model="form.code" placeholder="情输入通信设备ID">
          </Input>
        </FormItem>
        <FormItem prop="smallTypes" label="采集设备类型">
          <Select v-model="form.smallType" :placeholder="showLang('save.select')">
            <template v-for="(item, idx) in formArgs.smallTypes">
              <Option :value="item.id" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
        </FormItem>
        <FormItem prop="address" label="采集设备地址">
          <Input ref="address" type="number" v-model="form.address" placeholder="情输入采集设备地址">
          </Input>
        </FormItem>
        <FormItem prop="transChannel" label="上行通道号">
          <Input ref="transChannel" type="number" number v-model="form.transChannel" placeholder="上行通道号"> </Input>
        </FormItem>
        <FormItem prop="rate" label="倍率">
          <Input ref="rate" type="number" number v-model="form.rate" placeholder="倍率"> </Input>
        </FormItem>
        <FormItem prop="simCard" label="SIM卡号">
          <Input ref="simCard" type="text" v-model="form.simCard" placeholder="情输入通信SIM卡号">
          </Input>
        </FormItem>
        <FormItem prop="location" :label="showLang('com.Installation.position')">
          <Input ref="location" type="text" v-model="form.location" :placeholder="showLang('com.enter.Installation.position')">
          </Input>
        </FormItem>
        <FormItem prop="buildDate" :label="showLang('com.date.installation')">
          <DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.buildDate" :placeholder="showLang('com.date.select.installation')" :editable="false"></DatePicker>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalStationEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    formArgs: {
      type: Object,
      default(){ return {
        outputGroups: [],
        lightGroups: [],
        rules: [],
        smallTypes: [],
        transTypes: [],
      };}
    },
    outputGroups: {
      type: Array,
      default(){ return [];}
    },
    lightGroups: {
      type: Array,
      default(){ return [];}
    },
    rules: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      groupName: '',
      tabHeight: 640,
      dataRefresh: 0,
      // checkedKeys1: [],
      // checkedKeys2: [],
      form: {
        id: '',
        name: '',
        ruleId: 0,
        code: '',
        smallType: 0,
        transType: 0,
        address: 3,
        buildDate: '',
        location: '',
        simCard: '',
        transChannel: 3,
        rate: 1,
        isOuter: true,
      },
      channels: [],
      formRules: {
        // name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // avgDiff: { required: true, type: 'number', min: 0, message: '必须设置均差阈值', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.ruleId = this.item.data.ruleId;
          this.form.code = this.item.data.code;
          this.form.smallType = this.item.data.smallType;
          this.form.transType = this.item.data.transType;
          this.form.address = this.item.data.address;
          this.form.location = this.item.data.location;
          this.form.simCard = this.item.data.simCard;
          this.form.isOuter = this.item.data.isOuter;
          this.form.transChannel = this.item.data.transChannel;
          this.form.rate = this.item.data.rate;
          this.form.buildDate = new Date(this.item.data.buildDate).format('yyyy-MM-dd');
        }else{
          this.form.id = '';
          this.form.name = '';
          this.form.ruleId = 0;
          this.form.code = '';
          this.form.smallType = 0;
          this.form.transType = 0;
          this.form.address = 1;
          this.form.rate = 1;
          this.form.location = '';
          this.form.simCard = '';
          this.form.isOuter = true;
          this.form.transChannel = 3;
          this.form.buildDate = new Date().format('yyyy-MM-dd');
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'cmds']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  mounted: function(){
    // this.getProjects();
    // this.formRules.name.message=this.showLang('lux.name.null.leng20')
    // this.formRules.location.message=this.showLang('lux.position.null.leng20')
    // this.formRules.avgDiff.message=this.showLang('lux.set.rate.need')
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      // if(this.form.groupIds.length == 0){
      //   this.$Message.warning(this.showLang('lux.select.Control.grouping'));
      //   return;
      // }
      if(this.form.ruleId == 0){
        this.$Message.warning(this.showLang('lux.select.Apply.Rules'));
        return;
      }
      // if(this.form.avgDiff < 0){
      //   this.$Message.warning(this.showLang('lux.set.rate.need'));
      //   return;
      // }
      this.form.buildDate = new Date(this.form.buildDate).format('yyyy-MM-dd');
      // let chkOutputGroups = this.$refs.outputGroupTable.getCheckboxRecords();
      // let chkLightGroups = this.$refs.lightGroupTable.getCheckboxRecords();
      // this.form.outputGroups = chkOutputGroups.map(p => p.id);
      // this.form.lightGroups = chkLightGroups.map(p => p.id);
      this.$axios.post(`device/lux/SaveDevice`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info(this.showLang('com.tips.save'));
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-area{
  /* border: solid 1px red; */
  height: 500px;
  display: flex;
}
.lux-edit-area{
  /* border: solid 1px red; */
  display: flex;
}
.lux-form-area{
  /* border: solid 1px red; */
  width: 200px;
  flex: auto;
  padding-right: 15px;
}
.lux-group-area{
  /* border: solid 1px red; */
  width: 300px;
  flex: none;
  margin-left: 10px;
}
.edit-area{
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 250px;
  flex: none;
  margin-right: 10px;
  padding: 10px;
}
.channel-area{
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 290px;
  flex: auto;
}
</style>